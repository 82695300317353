.buttons-box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.1);
}

.start-button-box {
    position: absolute;
    bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-button {
    width: 86px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    color: #fff;
    margin: 0 10px;
    font-family: "Red Hat Display", 'Google Sans';
    font-size: 14px;
    font-weight: 900;
    transition: box-shadow 0.4s ease-in-out;
}

/* FOLD BUTTON */
.fold-button {
    background: #f44336;
    border: 4px solid #e93124;
}

.fold-button:hover {
    box-shadow: 0 0 20px #f44336;
}


/* BET BUTTON */
.bet-button {
    background: #8bc34a;
    border: 4px solid #6ea231;
}

.bet-button:hover {
    box-shadow: 0 0 20px #8bc34a;
}

/* CHECK BUTTON */
.check-button {
    background: #862EC3;
    border: 4px solid #5c2b7d;
    font-size: 14px;
}

.check-button:hover {
    box-shadow: 0 0 20px #862EC3;
}

/* START BUTTON */
.start-button {
    background: #8bc34a;
    border: 4px solid #6ea231;
    font-size: 17px;
}

.start-button:hover {
    box-shadow: 0 0 20px #8bc34a;
}


@media screen and (min-width: 1960px) {
    .buttons {
        transform: scale(1.5);
        bottom: 80px;
    }
}

@media screen and (max-width: 1024px) {

    .buttons-box {
        transform: scale(1);
    }

    .game-button {
        margin: 0px 7px;
    }

}

@media screen and (max-width: 860px) {

    .game-button {
        width: 80px;
        height: 40px;
        margin: 0 5px;
        border-radius: 7px;
    }

}

@media screen and (max-width: 600px) {

    .buttons-box {
        transform: scale(1);
        margin-top: -65px;
    }

    .buttons-box.right {
        margin-right: 0px;
    }

    .buttons-box.left {
        margin-left: 0px;
    }

    .game-button {
        margin: 0px 10px;
        height: 55px;
        width: 90px;
        font-size: 16px;
        font-weight: 700;
    }

    .game-button.check-button {
        position: absolute;
        bottom: -70px;
    }

    .start-button-box {
        position: fixed;
        z-index: 12;
        bottom: 50px;
        width: 110px;
    }

    .start-button-box .game-button {
        width: 120px;
        height: 40px;
    }

}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .buttons-box {
            transform: scale(1.2);
            margin-top: -20px;
        }

        .buttons {
            bottom: 17px;
        }

        .game-button {
            width: 80px;
            height: 40px;
            margin: 0 4px;
            font-size: 14px;
            margin-top: 10px;
        }

        .start-button-box {
            bottom: 10px;
        }

    }
}