.rates {
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    transform: scale(0.8);
    transition: all 0.4s;
}

.rates.rates-choice {
    transform: scale(0.95);
}


/* Trips box */

.trips-box {
    position: absolute;
    margin-top: 20px;
    margin-right: 200px;
    width: 80px;
    height: 80px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: margin-top 1s, margin-right 1s, margin-left 1s; */
}

.trips-box.right {
    margin-right: -200px;
}

.trips-box.disabledTrips {
    opacity: 0.4;
}

.trips-rhombus-outer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform-style: flat;
    transform-style: flat;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.trips-rhombus-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.r-trips {
    position: absolute;
    margin: 0px auto;
    border: 1px solid #cc9a00;
    width: 65px;
    height: 65px;
    z-index: 20;
    border-radius: 10px;
    transform: rotate(45deg);
    align-items: center;
    justify-content: center;
    box-shadow: 0 0px 5px rgba(126, 95, 0, 0.6);
}

.r-trips.animated {
    -webkit-animation: shadow-move 2s infinite;
    animation: shadow-move 2s infinite;
}

.trips {
    position: absolute;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
}

.trips-text {
    font-weight: 1000;
    position: absolute;
    z-index: -1;
    color: #cc9a00;
    font-family: "Red Hat Display", 'Google Sans';
    font-size: 12px;
}

.trips-chip-box {
    animation: bet-chip-box 0.6s ease-in-out 0s 1 forwards;
}

.trips-chip-box.fold {
    animation: chip-box-fold 0.6s ease-in-out 0s 1 forwards;
}

/* ANTE & BLIND */

.ante-blind {
    position: relative;
    width: 145px;
    height: 80px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.rhombus-outer {
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: visible;
    -webkit-transform-style: flat;
    transform-style: flat;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.rhombus-inner {
    width: 100%;
    height: 100%;
}

.rhombus {
    position: absolute;
    margin: 0px auto;
    border: 1px solid #cc9a00;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    transform: rotate(45deg);
    align-items: center;
    justify-content: center;
    background-color: #362f1c;
    box-shadow: 0 5px 5px rgba(126, 95, 0, 0.6);
}

.rhombus.animated {
    -webkit-animation: shadow-move 2s infinite;
    animation: shadow-move 2s infinite;
}

.rhombus.ante {
    left: 0;
    z-index: 1;
}

.rhombus.blind {
    right: 0;
}

.ante-blind .front {
    position: absolute;
    width: 165px;
    height: 80px;
    margin-bottom: 10px;
    margin-left: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.front .ante,
.front .blind {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 4px;
    position: relative;
}

.ante-text {
    font-weight: 1000;
    position: absolute;
    z-index: -1;
    color: #cc9a00;
    font-family: "Red Hat Display", 'Google Sans';
    font-size: 12px;
}

.u-bet {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bet {
    width: 75px;
    height: 75px;
    border-radius: 40px;
    border: 1px solid #cc9a00;
    background: rgba(126, 95, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
    box-shadow: 0 5px 5px rgba(126, 95, 0, 0.6);
    position: relative;
}

.bet-text {
    font-weight: 1000;
    position: absolute;
    z-index: -1;
    color: #cc9a00;
    font-family: "Red Hat Display", 'Google Sans';
    font-size: 12px;
}




.chip-box {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.chip-value {
    position: absolute;
    z-index: 1;
    font-size: 15px;
    font-family: "Red Hat Display", 'Google Sans';
    font-weight: 900;
    background-color: white;
    border-radius: 10px;
}




.animated-chip {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 1;
}

.animated-chip.visible {
    animation: animated-chip 0.4s ease-in-out 0s 1 forwards;
}

.animated-chip.hidden {
    animation: animated-hidden 0.4s ease-in-out 0s 1 forwards;
}

@keyframes animated-chip {

    0% {
        transform: translateY(220px);
        opacity: 1;
    }

    50% {
        transform: translateY(75px);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 0;
    }

}


@keyframes animated-hidden {

    0% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(75px);
        opacity: 1;
    }

    100% {
        transform: translateY(220px);
        opacity: 0;
    }

}



.ante-chip-box {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
}

.ante-chip-box.fold {
    animation: chip-box-fold 0.7s ease-in-out 0s 1 forwards;
}

@keyframes chip-box-fold {

    0% {
        transform: translateY(0);
        opacity: 1;
    }

    30% {
        transform: translateY(-20px);
        opacity: 1;
    }

    100% {
        transform: translateY(-110vh);
        opacity: 0;
    }

}




.bet-chip-box {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    animation: bet-chip-box 0.6s ease-in-out 0s 1 forwards;
}

.bet-chip-box.fold {
    animation: chip-box-fold 0.6s ease-in-out 0s 1 forwards;
}

@keyframes bet-chip-box {

    0% {
        transform: translateY(150px);
    }

    50% {
        transform: translateY(75px);
    }

    100% {
        transform: translateY(0);
    }

}




.rate-win-ab {
    position: absolute;
    left: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.rate-win-ab-text {
    position: absolute;
    bottom: 60px;
    font-family: "Red Hat Display", 'Google Sans';
    font-size: 20px;
    color: #FFD643;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    text-shadow: 2px 2px 1px #b71010, 0 5px 10px rgba(0, 0, 0, 0.6);
    animation: chip-result-rate-text 0.4s ease-in-out 0.4s forwards;
    opacity: 0;
}

.rate-win-ab-chip {
    animation: chip-result-rate-box 0.4s ease-in-out 0s 1 forwards;
}



.rate-win-bet {
    position: absolute;
    left: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.rate-win-bet-text {
    position: absolute;
    bottom: 60px;
    font-family: "Red Hat Display", 'Google Sans';
    font-size: 20px;
    color: #FFD643;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    text-shadow: 2px 2px 1px #b71010, 0 5px 10px rgba(0, 0, 0, 0.6);
    animation: chip-result-rate-text 0.4s ease-in-out 0.4s forwards;
    opacity: 0;
}

.rate-win-bet-chip {
    animation: chip-result-rate-box 0.4s ease-in-out 0s 1 forwards;
}

@keyframes chip-result-rate-box {
    from {
        transform: translateY(-110vh);
    }

    to {
        transform: translateY(20px);
    }
}

@keyframes chip-result-rate-text {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@media screen and (min-width: 1960px) {
    .rates {
        transform: scale(1);
        margin-top: 20px;
    }
}


@media screen and (max-width: 600px) {

    .rates {
        transform: scale(1.05);
        margin-top: 50px;
    }

    .rates.rates-choice {
        transform: scale(1);
        margin-top: 40px;
    }

    .rates.right {
        margin-left: -15px;
    }

    .rates.left {
        margin-right: -15px;
    }


    @media screen and (max-height: 720px) {
        .rates.rates-choice {
            margin-top: -30px;
        }
    }

    @media screen and (max-height: 670px) {
        .rates.rates-choice {
            margin-top: -70px;
        }
    }


    @media screen and (max-height: 640px) {
        .rates.rates-choice {
            margin-top: -100px;
        }
    }

}

@media screen and (max-width: 420px) {
    .rates.rates-choice {
        margin-top: 80px;
    }

    @media screen and (max-height: 700px) {
        .rates.rates-choice {
            margin-top: 40px;
        }
    }

    @media screen and (max-height: 660px) {
        .rates.rates-choice {
            margin-top: 0px;
        }
    }

    @media screen and (max-height: 620px) {
        .rates.rates-choice {
            margin-top: -60px;
        }
    }

}

@media screen and (max-width: 375px) {
    .rates.rates-choice {
        margin-top: 80px;
    }
    @media screen and (max-height: 720px) {
        .rates.rates-choice {
            margin-top: 40px;
        }
    }
    @media screen and (max-height: 620px) {
        .rates.rates-choice {
            margin-top: -20px;
        }
    }

    @media screen and (max-height: 585px) {
        .rates.rates-choice {
            margin-top: -40px;
        }
    }
}

@media screen and (max-width: 330px) {
    .rates.rates-choice {
        margin-top: 70px;
    }

    @media screen and (max-height: 600px) {
        .rates.rates-choice {
            margin-top: 20px;
        }
    }

    @media screen and (max-height: 565px) {
        .rates.rates-choice {
            margin-top: -20px;
        }
    }

    @media screen and (max-height: 545px) {
        .rates.rates-choice {
            margin-top: -40px;
        }
    }


}


@media screen and (max-height: 720px) {
    @media (orientation: landscape) {

        .rates {
            transform: scale(0.8);
        }

        .rates.rates-choice {
            transform: scale(0.8);
        }
    }
}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .rates.rates-choice {
            transform: scale(0.9);
        }
    }
}