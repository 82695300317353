.box-inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(0.55);
}

.box-inner.hidden {
    opacity: 0;
}

.box-inner.left {
    left: 20px;
    align-items: flex-start;
    transform-origin: center left;
    -webkit-transform-origin: center left;
    -moz-transform-origin: center left;
}

.box-inner.right {
    right: 20px;
    align-items: flex-end;
    transform-origin: center right;
    -webkit-transform-origin: center right;
    -moz-transform-origin: center right;
}

.box-cards-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 400px;
    background-color: rgba(0, 0, 0, 0.2);
    transform-origin: top right;
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    padding: 0px 15px;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
}

.box-cards-block.left {
    transform-origin: center left;
    -webkit-transform-origin: center left;
    -moz-transform-origin: center left;
}

.box-cards-block-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box-cards-block-inner.dealer {
    /* position: absolute;
    bottom: 193px; */
    border-bottom: 0.5px solid rgb(255 255 255 / 58%);
}

.player-text, .result-name {
    color: white;
    font-size: 22px;
    margin: 8px 0px;
    font-weight: 500;
}

.box-cards {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    animation: box-animation 0.3s ease-in-out 0s 1 forwards;
}

.box-cards.right {
    justify-content: flex-end;
}

.box-card {
    width: 70px;
    height: 105px;
    margin: 0 5px;
}

.box-card.blured {
    opacity: 0.5;
}

.box-card img {
    width: 70px;
    height: 105px;
    object-fit: contain;
}

.box-flip-card {
    background-color: transparent;
    width: 70px;
    height: 105px;
    perspective: 1000px;
}

.box-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-flip-card .box-flip-card-inner {
    transform: rotateY(180deg);
}

.box-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-flip-card-back {
    color: white;
    transform: rotateY(180deg);
}

.box-flip-card-back.blured {
    opacity: 1;
    filter: blur(1px);
    -webkit-filter: blur(1px);
    overflow: hidden;
    border-radius: 7px;
}

.box-card .animated {
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    height: 105px;
    transform: translateY(0);
}

.box-card .animated::after {
    content: "";
    border: 16px solid transparent;
    border-bottom: 16px solid #ba8c04;
    border-left: 16px solid #ba8c04;
    position: absolute;
    left: 0;
    bottom: -5px;
    z-index: 100;
    transform: rotate(-8deg);
    opacity: 1;
}

@keyframes box-animation {

    0% {
        height: 0px;
        opacity: 0;
    }

    100% {
        transform: 140px;
        opacity: 1;
    }

}

@media screen and (max-width: 1024px) {
    .box-inner {
        transform: scale(0.5);
    }
}

@media screen and (max-width: 860px) {
    .box-inner {
        transform: scale(0.4);
    }
}

@media screen and (max-width: 600px) {

    .box-inner {
        bottom: 250px;
        transform: scale(0.32);
    }

    .box-cards-block {
        padding: 0px 15px;
    }

    .box-inner.left {
        left: 15px;
        transform-origin: bottom left;
        -webkit-transform-origin: bottom left;
        -moz-transform-origin: bottom left;
    }
    
    .box-inner.right {
        right: 15px;
        transform-origin: bottom right;
        -webkit-transform-origin: bottom right;
        -moz-transform-origin: bottom right;
    }

    .box-inner.centered {
        right: 0px !important;
        left: 0px !important;
        transform-origin: bottom center;
        -webkit-transform-origin: bottom center;
        -moz-transform-origin: bottom center;
    }

}