/* Result temp style */

.result {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .5s;
    position: absolute;
    transform: scale(0.9);
}

.result.right {
    margin-right: -20px;
}

.result.left {
    margin-left: -20px;
}

.result.visible {
    z-index: 15;
    opacity: 1;
    animation: result-appear 0.8s ease-in-out 0s 1 forwards;
}

.result.hidden {
    z-index: -1;
    opacity: 0;
}

.result-box {
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 200px 150px rgba(0, 0, 0, 0.8);
    background: black;
}

.result-text {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.result-text img {
    height: 10px;
    object-fit: contain;
    width: 50px;
}

.result-text span {
    color: #fff;
    font-family: Red Hat Display, Google Sans, monospace;
    font-weight: 900;
    padding: 0 10px;
    white-space: nowrap;
    font-size: 22px;
}

.result-text span.small {
    font-size: 17px;
    position: absolute;
    top: -21px;
}

.result-draw-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.result-draw-text span {
    color: #ffffff
}

.result-draw-price {
    padding: 0 10px;
    color: white;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-weight: 900;
    margin-top: 2px;
    font-size: 28px;
    white-space: nowrap;
}

.result-win-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.result-win-text span {
    color: #ffffff
}

.result-win-price {
    color: #ffe500;
    font-family: Red Hat Display, Google Sans, monospace;
    font-size: 28px;
    font-weight: 900;
    padding: 0 10px;
    margin-top: 2px;
    white-space: nowrap;
}

@media screen and (max-width: 600px) {

    .result {
        transform: scale(0.8);
    }

    .result.visible {
        animation: result-appear-mobile 0.8s ease-in-out 0s 1 forwards;
    }

    .result-box {
        margin-bottom: 25px;
        box-shadow: none;
    }

    .result-text {
        margin-bottom: 0px;
    }

    .result-lose-space {
        padding-top: 60px;
    }
}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {
        .result-box {
            box-shadow: none;
        }

        .result.visible {
            animation: result-appear-mobile 0.8s ease-in-out 0s 1 forwards;
        }

    }
}


@keyframes result-appear {

    0% {
        transform: scale(0.6);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.9);
    }
}

@keyframes result-appear-mobile {

    0% {
        transform: scale(0.6);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(0.8);
    }
}