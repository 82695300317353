/* PLAYER CARD GAME STYLES */
.player-container {
    min-width: 160px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    position: relative;
    transform: scale(0.8);
}

.game.hidden .player-container {
    opacity: 0.5;
}


/* CONTAINER HEAD STYLES */
.player-container-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 25px;
    margin-bottom: 10px;
}

.player-container-title {
    font-size: 14px;
    font-weight: 700;
    padding: 3px 5px;
    color: white;
    font-family: "Red Hat Display", 'Google Sans';
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-game-status {
    padding: 4px 8px;
    font-weight: 900;
    color: white;
    border-radius: 10px;
    font-size: 13px;
    background: #8bc34a;
    backdrop-filter: blur(4px);
    font-family: "Red Hat Display", 'Google Sans';
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-game-status.red {
    background-color: #f44336;
}




/* CARD STYLES */
.player-cards {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 160px;
}

.player-card {
    width: 70px;
    height: 105px;
    margin: 0 5px;
    animation: player-card 0.3s ease-in-out 0s 1 forwards;
}

.player-card.fixed {
    animation: player-card-opacity 0.4s ease-in-out 0s 1 forwards;
}

.player-card.blured {
    /* opacity: 0.25 !important; */
}

.player-card.remove {
    animation: player-card-remove 0.3s ease-in-out 0s 1 forwards;
    width: 0;
    transition: width 0.4s;
    margin: 0;
    overflow: hidden;
}

.player-card img {
    width: 70px;
    height: 105px;
    object-fit: cover;
    box-shadow: 0 0 10px #222;
    border-radius: 14px;
}

.player-flip-card {
    background-color: transparent;
    width: 70px;
    height: 105px;
    perspective: 1000px;
}

.player-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-flip-card.open .player-flip-card-inner {
    transform: rotateY(180deg);
}

.player-flip-card-front,
.player-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-flip-card-front {
    color: black;
}

.player-flip-card-back {
    color: white;
    transform: rotateY(180deg);
}

@keyframes player-card {

    0% {
        transform: translateX(100vw) translateY(-100vh);
    }

    100% {
        transform: translateX(0) translateY(0);
    }

}

@keyframes player-card-opacity {

    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }

}

@-webkit-keyframes player-card-opacity {

    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }

}

@keyframes player-card-remove {

    0% {
        transform: translateX(0) translateY(0);
    }

    100% {
        transform: translateX(-100vw) translateY(-100vh);
    }

}



/* ANIMATED STYLES */
.player-card .player {
    height: 105px;
}

.player-card .animated {
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    height: 105px;
    transform: translateY(0);
}

.player-card .animated::after {
    content: "";
    border: 16px solid transparent;
    border-bottom: 16px solid #ba8c04;
    border-left: 16px solid #ba8c04;
    position: absolute;
    left: 0;
    bottom: -5px;
    z-index: 100;
    transform: rotate(-8deg);
    /* animation: player-card-animated-corner 0.4s linear 1s 1 forwards; */
    opacity: 1;
}

/* @keyframes player-card-animated-corner {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

} */



/* PLAYER ADDITIONAL STATUS STYLES */
.player-card-additional {
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    font-family: "Red Hat Display", 'Google Sans';
    background: #ff9800;
    box-shadow: 0 0 20px #ff9800;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
}

.player-card-additional .arrow {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-left: 3px;
    box-shadow: none !important;
}





/* PLAYER SPACE STYLES */
.player-spaces {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 160px;
    position: absolute;
    z-index: -1;
    top: 35px;
}

.player-space {
    width: 70px;
    height: 105px;
    border-radius: 14px;
    border: 1px dashed #a58844;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 5px;
    opacity: 0.6;
}

.player-space.space-shadow {
    animation: player-space-shadow 2s ease-in-out 1s infinite forwards;
}

.player-space-circle {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: #af9147;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-space-diamond {
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    background: #212133;
}

.player-space-round {
    border: 1px dashed #af9147;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-left: -5px;
    opacity: 0.4;
}



@keyframes player-space-shadow {

    0% {
        box-shadow: 0 0 10px #9e9e9e;
    }

    50% {
        box-shadow: 0 0 20px #9e9e9e;
    }

    100% {
        box-shadow: 0 0 10px #9e9e9e;
    }

}

@media screen and (min-width: 1960px) {
    .player-container {
        transform: scale(1.5);
    }
}

@media screen and (max-width: 992px) {
    .player-container {
        min-width: 98px;
    }

    .player-container-head {
        height: 25px;
        margin-top: 10px;
        margin-bottom: 5px;
        padding: 0px;
        display: flex;
        align-items: center;
    }

    .player-container-title {
        font-size: 13px;
        height: 25px;
        align-items: center;
    }

    .player-cards {
        min-width: 98px;
    }

    .player-card {
        width: 45px;
        height: 67.5px;
        margin: 0 2px;
    }

    .player-card .dealer {
        height: 67.5px;
    }

    .player-flip-card {
        width: 45px;
        height: 67.5px;
    }

    .player-card img {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
    }

    .player-card .player {
        height: 67.5px;
    }

    .player-card .animated {
        height: 67.5px;
        border-radius: 5px;
    }

    .player-spaces {
        min-width: 98px;
        top: 40px;
    }

    .player-space {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
        margin: 0 2px;
    }

    .player-game-status {
        height: 20px;
        padding: 0 12px;
        font-size: 12px;
    }

    .player-card .animated::after {
        bottom: -10px;
    }

}


@media screen and (max-width: 600px) {

    .player-container {
        transform: scale(1);
        position: absolute;
        right: 0;
        max-width: calc(100% - 80px);
        min-width: 102px;
        height: 120px;
        align-items: flex-end;
        padding-right: 10px;
    }

    .box-container.bottom .player-container {
        display: none;
    }

    .player-container {
        width: 78px;
        transform: scale(1);
    }

    .game.hidden .player-container {
        opacity: 0;
    }

    .player-container-head {
        height: 20px;
        margin-top: 10px;
        margin-bottom: 5px;
        padding: 0px;
        display: flex;
        align-items: center;
    }

    .player-container-title {
        font-size: 9px;
        height: 20px;
        align-items: center;
        display: flex;
    }

    .player-cards {
        min-width: 78px;
    }

    .player-card {
        width: 35px;
        height: 52.5px;
        margin: 0 2px;
    }
    

    .player-card .player {
        height: 52.5px;
    }

    .player-flip-card {
        width: 35px;
        height: 52.5px;
    }

    .player-card img {
        width: 35px;
        height: 52.5px;
        border-radius: 5px;
    }

    .player-card .animated {
        height: 52.5px;
        border-radius: 5px;
    }

    .player-spaces {
        min-width: 78px;
        top: 35px;
    }

    .player-space {
        width: 35px;
        height: 52.5px;
        border-radius: 5px;
        margin: 0 2px;
    }

    .player-game-status {
        height: 16px;
        padding: 0 6px;
        font-size: 8px;
    }

    .player-game-status.smaller {
        font-size: 9px;
        padding: 0 4px;
    }

    .player-card .animated::after {
        border: 11px solid transparent;
        border-bottom: 11px solid #ba8c04;
        border-left: 11px solid #ba8c04;
    }

}



@media screen and (max-height: 320px) {
    .player-container {
        top: 0px;
    }
}