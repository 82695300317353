.box-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.box-container .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.box-middleware {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}
    

@media screen and (max-width: 600px) {

    .box-container .box {
        width: 50%;
        padding: 15px;
    }

    .box-container .box.single {
        width: 100%;
    }

    .box-middle-space {
        height: 32px;
    }

    @media screen and (max-height: 620px) {
        .box-middle-space {
            height: 28px;
        }
    }

    @media screen and (max-height: 580px) {
        .box-middle-space {
            height: 20px;
        }
    }
    
}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {
        .box-middleware {
            height: 80px;
        }
    }
}