@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Regular.ttf');
    src: url('../fonts/GoogleSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Medium.ttf');
    src: url('../fonts/GoogleSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Bold.ttf');
    src: url('../fonts/GoogleSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

:root {
    --primary: #78D600;
    --secondary: #F7DB4C;
    touch-action: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Google Sans', sans-serif;
}

html,
body,
#root {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #0e1015;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}


body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar {
    display: none;
    height: 0px;
    width: 30px;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar,
#root::-webkit-scrollbar,
div::-webkit-scrollbar {
    display: none;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

.back-outer {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.background {
    position: absolute;
    z-index: 3;
    left: -20vw;
    width: 140vw;
    height: 140vw;
    bottom: calc(-5vw + 40px);
    border-radius: 0 0 140vh 140vh;
    background-image: radial-gradient(circle, #605538, #4d442b, #3b331f, #2a2313, #1b1400);
    border: 2px solid #020203;
    display: flex;
    justify-content: center;
    align-items: center;
}

.glass {
    position: absolute;
    z-index: 2;
    left: -20vw;
    width: 140vw;
    height: 140vw;
    bottom: -5vw;
    border-radius: 0 0 140vh 140vh;
    background: rgb(23, 25, 33, 0.4);
    backdrop-filter: blur(10px);
}

.shadow {
    position: absolute;
    z-index: 1;
    left: -20vw;
    width: 140vw;
    height: 140vw;
    bottom: calc(-5vw - 2px);
    border-radius: 0 0 140vh 140vh;
    animation: shadow-animation 4s ease-in-out 0s infinite forwards;
    border: 2px solid #020203
}

@keyframes shadow-animation {

    0% {
        box-shadow: 0 0 70px 1px #ff9800;
    }

    50% {
        box-shadow: 0 0 100px 1px #ff9800;
    }

    100% {
        box-shadow: 0 0 70px 1px #ff9800;
    }

}


.content {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.game {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.game.visible {
    z-index: 15;
    opacity: 1;
    transition: opacity 0.4s;
}

.game.hidden {
    z-index: -1;
}


.makao {
    position: absolute;
    z-index: 7;
    left: 0;
    right: 0;
    bottom: 185px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.4s;
}

.makao.visible {
    opacity: 0.1;
}

.makao.hidden {
    opacity: 0;
}

.makao img {
    width: 120px;
}

.fullscreen {
    min-height: 800px;
    overflow: hidden;
}

@media screen and (min-width: 1960px) {

    .fullscreen {
        min-height: 1400px;
    }

    .game-info {
        transform: scale(1.6);
        left: 160px !important;
    }

    .content {
        top: 50px;
    }

    .game {
        bottom: 175px;
    }

    .makao {
        transform: scale(1.5);
    }
}


@media screen and (max-width: 660px) {

    .background {
        height: 100vh;
    }

    .game {
        justify-content: flex-end;
    }

    .makao {
        min-height: 470px;
    }

    .swal2-popup.swal2-toast {
        margin-top: 60px;
    }

}

@media screen and (max-height: 600px) {

    @media (orientation: landscape) {

        .background {
            height: 150vh;
            bottom: calc(-5vw + 20px);
        }

        .content {
            top: 10px;
        }

        .game {
            bottom: 0px;
        }

        .makao {
            bottom: auto;
            top: 90px;
            transform: scale(0.7);
        }

        .game {
            justify-content: space-around;
        }
    }
}

@media screen and (max-height: 320px) {
    .makao {
        bottom: auto;
        top: 82px;
        transform: scale(0.6);
    }
}

/* Page 404 Styles */
.page-404 {
    height: 100vh;
    background: white;
    padding: 20px;
    font-weight: 100;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #666
}

.page-404 span {
    font-size: 80px;
    font-weight: 900;
    color: #999;
}