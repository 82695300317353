.card-container {
    width: 70px;
    height: 105px;
    border-radius: 15px;
    background-image: linear-gradient(to right bottom, #fefdff, #fcfbfe, #faf8fe, #f8f6fd, #f6f4fd);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.card-container-head {
    transform: scale(0.7);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 50%;
    padding: 10px;
    margin-top: -7px;
    margin-left: -17px;
}

.card-container-head-text {
    color: #000;
    font-size: 50px;
    line-height: 40px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-weight: 700;
}

.card-container-head-text.red {
    color: #CD2E4B
}

.card-container-head-suit {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5px;
    margin-top: 5px;
}

.card-container-head-suit.player {
    margin-left: 5px;
    margin-top: 5px;
}

.card-container-bottom {
    width: 100%;
    height: 50%;
    margin-right: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.card-container-bottom-inner {
    transform: scale(0.7);
}


.dealer-card-animated .card-container {
    animation: card-container-animated-image 2s ease-in-out 1s infinite forwards;
}

@keyframes card-container-animated-image {

    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes card-container-animated-image {

    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes card-container-animated-corner {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}

/* @media screen and (max-width: 992px) {
    .card-container {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
    }

    .card-container-head {
        margin-left: -14px;
        transform: scale(0.5);
        padding: 0;
        margin-top: -5px;
    }

    .card-container-bottom {
        margin-right: -15px;
        margin-bottom: -8px;
    }

    .card-container-bottom-inner {
        transform: scale(0.8);
    }

    .card-container-bottom-inner {
        transform: scale(0.5);
    }
} */


/* @media screen and (max-width: 600px) {

    .card-container,
    .card-container {
        width: 35px;
        height: 52.5px;
        border-radius: 5px;
    }

    .card-container-head {
        transform: scale(0.4);
        padding: 0;
        margin-top: -5px;
        margin-left: -14px;
    }

    .card-container-bottom {
        margin-right: -15px;
        margin-bottom: -8px;
        transform: scale(0.8);
    }

    .card-container-bottom-inner {
        transform: scale(0.5);
    }
} */


/* @media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .card-container {
            width: 30px;
            height: 45px;
            border-radius: 4px;
        }

        .card-container-head-text {
            font-size: 35px;
        }

        .card-container-bottom {
            margin-right: -25px;
            margin-bottom: -12.5px;
        }

        .card-container-bottom-inner {
            transform: scale(0.4);
        }

        .card-container-head {
            transform: scale(0.4);
            padding: 0;
            margin-top: -5px;
            margin-left: -12px;
        }
    }
} */