.choice {
    height: 70px;
    position: absolute;
    top: calc(50vh - 135px);
    transition: opacity 0.3s;
    opacity: 0;
    z-index: -10;
    height: 0px;
}

.choice.visible {
    opacity: 1;
    z-index: 10;
    height: auto;
}

.chips-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
}

.chips-container::after {
    content: "";
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 0px;
    box-shadow: 0 0 100px 100px rgba(0, 0, 0, 0.3);
    width: 600px;
}

.chips-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chip {
    margin: 0 5px;
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    transition: box-shadow 0.3s;
}

.chip.active {
    box-shadow: 0 0 10px #ffc107;
}


.chips-button {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.4);
    margin: 0 5px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.chips-button span {
    height: 24px;
    font-size: 22px;
    margin-bottom: 10px;
    font-family: "Red Hat Display", 'Google Sans';
    font-weight: 900;
}

.chips-button img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-bottom: 10px;
}

@media screen and (min-width: 1960px) {
    .choice {
        transform: scale(1.5);
        top: calc(50vh - 350px);
    }
}

@media screen and (max-width: 960px) {
    .choice {
        transform: scale(0.85);
    }
}

@media screen and (max-width: 776px) {
    .choice {
        transform: scale(0.75);
    }
}



@media screen and (max-width: 600px) {

    .choice {
        top: calc(50vh - 60px);
        transform: scale(0.85);
    }

    .chips-box {
        position: absolute;
        bottom: 85px;
    }

    .chips-box .chip {
        width: 50px;
        height: 50px;
        margin: 0 2px;
    }

    .chips-box .chip .chip-box {
        width: 50px !important;
        height: 50px !important;
    }

    .chips-box .chip .chip-box .chip-value {
        transform: scale(0.8);
    }

    .chips-button {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        font-size: 11px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 100px black;
    }

    .chips-button img {
        width: 20px;
        height: 20px;
    }

    .chips-button span {
        font-size: 20px;
    }
}


@media screen and (max-height: 600px) {
    @media (orientation: landscape) {
        .choice {
            top: calc(50vh - 70px);
            transform: scale(0.75);
        }

        .chips-box {
            position: absolute;
            bottom: 85px;
        }

        .chips-box .chip {
            width: 60px;
            height: 60px;
            margin: 0 3px;
        }

        .chips-box .chip .chip-box {
            width: 60px !important;
            height: 60px !important;
        }

        .chips-box .chip .chip-box .chip-value {
            transform: scale(0.8);
        }

        .chips-button {
            width: 70px;
            height: 70px;
            border-radius: 10px;
            font-size: 11px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 100px black;
        }

        .chips-button img {
            width: 20px;
            height: 20px;
        }

        .chips-button span {
            font-size: 20px;
        }
    }
}