.table-cards-box {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

.table-cards-box-inner {
    top: 110px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(0.95);
    transform-origin: top;
}

@media screen and (max-height: 920px) {
    .table-cards-box-inner {
        top: 80px;
    }
}

@media screen and (max-height: 830px) {
    .table-cards-box-inner {
        top: 40px;
    }
}

/* Player box */
.table-player-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 650px;
}

.table-player-box.all {
    justify-content: space-between;
}

.table-player-box-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.table-middleware-box {
    height: 10px;
    position: relative;
}

.table-cards-head {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10px;
    top: -5px;
    padding-right: 5px;
}

.table-cards-head-title {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 1000;
    padding: 0 5px;
    color: white;
    font-family: "Red Hat Display", 'Google Sans';
}

.table-cards-head-result {
    margin-left: 3px;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 700;
    padding: 2px 8px;
    color: white;
    border-radius: 5px;
    font-family: "Red Hat Display", 'Google Sans';
    background-color: rgb(25, 162, 25);
    text-transform: uppercase;
}

.table-cards-head-result.red {
    background-color: rgb(229, 42, 42);
}

.table-cards-head-result.green {
    background-color: rgb(25, 162, 25);
}

/* Table Cards */

.table-cards {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 160px;
    height: 140px;
    transition: all 0.2s;
}

.table-cards-box-inner.table-choice .table-cards {
    opacity: 0.2;
}

.table-cards.additional {
    width: 400px;
}

.table-card {
    width: 70px;
    height: 105px;
    margin: 0 5px;
    animation: table-card 0.3s ease-in-out 0s 1 forwards;
}

.table-card.blured {
    opacity: 0.6;
}

.table-card img {
    width: 70px;
    height: 105px;
    object-fit: contain;
}

.table-flip-card {
    background-color: transparent;
    width: 70px;
    height: 105px;
    perspective: 1000px;
}

.table-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-flip-card.open .table-flip-card-inner {
    transform: rotateY(180deg);
}

.table-flip-card-front,
.table-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-flip-card-front {
    color: black;
}

.table-flip-card-back {
    color: white;
    transform: rotateY(180deg);
}

.table-flip-card-back.blured {
    opacity: 1;
    filter: blur(1px);
    -webkit-filter: blur(1px);
    overflow: hidden;
    border-radius: 7px;
}

@keyframes table-card {

    0% {
        transform: translateX(100vw) translateY(-100vh);
    }

    100% {
        transform: translateX(0) translateY(0);
    }

}


/* Table cards space */

.table-cards-spaces {
    display: flex;
    justify-content: center;
    align-self: center;
    position: absolute;
    z-index: -1;
    left: 0;
}

.table-card-space {
    width: 70px;
    height: 105px;
    border-radius: 13px;
    border: 1px dashed #a58844;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
}

.table-card-space-circle {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: #af9147;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-card-space-diamond {
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    background: #212133;
}

.table-card-space-round {
    border: 1px dashed #af9147;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-left: -5px;
}

@media screen and (min-width: 1960px) {
    .table-cards-box-inner {
        top: 40px;
        transform: scale(1.5);
    }
}

@media screen and (max-width: 1120px) {
    .table-cards-box-inner {
        top: 60px;
    }
}


@media screen and (max-width: 1024px) {
    .table-cards-box-inner {
        transform: scale(0.8);
    }
}


@media screen and (max-width: 600px) {
    .table-cards-box-inner {
        top: 75px;
        transform: scale(0.7);
    }

    .table-player-box {
        width: 100%;
    }

    .table-middleware-box {
        height: 20px;
    }

    .table-middleware-box.player {
        height: 65px;
    }

}

@media screen and (max-width: 420px) {
    .table-cards-box-inner {
        transform: scale(0.6);
    }

    .table-middleware-box {
        height: 15px;
    }

    .table-middleware-box.player {
        height: 60px;
    }

}

@media screen and (max-width: 375px) {
    .table-cards-box-inner {
        transform: scale(0.55);
    }
}

@media screen and (max-width: 330px) {
    .table-cards-box-inner {
        transform: scale(0.5);
    }
}



@media (orientation: landscape) {
    @media screen and (max-height: 740px) {
        .table-cards-box-inner {
            transform: scale(0.9);
        }
    }

    @media screen and (max-height: 650px) {
        .table-cards-box-inner {
            transform: scale(0.85);
        }
    }

    @media screen and (max-height: 600px) {
        .table-cards-box-inner {
            top: 10px;
            transform: scale(0.7);
        }
    }

    @media screen and (max-height: 500px) {
        .table-cards-box-inner {
            transform: scale(0.6);
        }
    }

    @media screen and (max-height: 450px) {
        .table-cards-box-inner {
            transform: scale(0.55);
        }
    }

    @media screen and (max-height: 410px) {
        .table-cards-box-inner {
            transform: scale(0.5);
        }
    }

    @media screen and (max-height: 380px) {
        .table-cards-box-inner {
            transform: scale(0.45);
        }
    }

    @media screen and (max-height: 350px) {
        .table-cards-box-inner {
            transform: scale(0.4);
        }
    }

    @media screen and (max-height: 310px) {
        .table-cards-box-inner {
            transform: scale(0.35);
        }
    }

    @media screen and (max-height: 280px) {
        .table-cards-box-inner {
            top: 5px;
            transform: scale(0.3);
        }
    }

}